'use strict';

angular.module('forms.helpers', [])

.directive('inputGroup', [
    '$timeout', '$interpolate',
    function($timeout, $interpolate) {
        var linkFn = function(scope, element, attrs, formCtrl) {
            var inputElement   = element[0].querySelector('.form-control[name], input[type="checkbox"][name]'),
                inputNgElement = angular.element(inputElement),
                inputName      = $interpolate(inputNgElement.attr('name') || '')(scope);

            if (! inputName) {
                throw "input-group element has no child input elements with a 'name' attribute and a 'form-control' class";
            }

            var tagName = inputNgElement[0].tagName;
            var required = inputNgElement.attr('required');
            var validation = inputNgElement.attr('validation');

            if ((typeof required !== 'undefined' && required !== false) ||
                (typeof validation !== 'undefined' && validation.indexOf('required') >= 0)) {
                element.addClass('required');
            }

            if (tagName === 'SELECT') {
                element.addClass('select');
            }

            if (inputNgElement[0].type.toLowerCase() === "checkbox") {
                var label = angular.element(element[0].querySelector('label'));
                label.append('<button type="button" class="checkbox-switch-button"></button>');

                var button = angular.element(element[0].querySelector('.checkbox-switch-button'));
                button.on('click', function(event) {
                    event.preventDefault();
                    inputNgElement[0].click();
                });

                // Apply the .checkbox-switch styling to override
                // default margins and paddings.
                element.addClass('checkbox-switch');

                scope.$watch(function() {
                    return inputNgElement[0].checked;
                }, function (value) {
                    if (value && ! element.hasClass('in')) {
                        element.addClass('in');
                    }

                    if (! value && element.hasClass('in')) {
                        element.removeClass('in');
                    }
                });
            }

            if (element[0].querySelector('.label-float')) {
                element.addClass('float');
            }

            // Processing icon
            element.append('<i class="input-icon fa fa-fw fa-spin fa-circle-o-notch"></i>');

            inputNgElement.bind('focus', function() {
                element.addClass('focus');
            });

            inputNgElement.bind('blur', function() {
                element.removeClass('focus');
            });

            // Toggle active class.
            scope.$watch(function() {
                return formCtrl[inputName] && formCtrl[inputName].$viewValue;
            }, function (dirty) {
                if (dirty) {
                    return element.addClass('in');
                }

                return element.removeClass('in');
            });

            // Toggle has-error class.
            scope.$watch(function() {
                return formCtrl[inputName] && ! formCtrl[inputName].$valid && formCtrl[inputName].$touched;
            }, function (invalid) {
                // When the '.form-group' wrapper has the '.has-error' class and the targeted
                // input is still pristine we're handling a PHP redirect.
                // In this case we don't want to manipulate the input and keep it as is
                // preserving the error class.
                if (element.hasClass('has-error') && formCtrl[inputName].$pristine) {
                    return;
                }

                // When the input is manipulated and results in an invalid state
                // we want to add the '.has-error' class to the '.form-group'.
                if (invalid) {
                    return element.addClass('has-error');
                }

                return element.removeClass('has-error');
            });

            // Toggle processing class.
            scope.$watch(function() {
                return formCtrl[inputName] && formCtrl[inputName].$processing;
            }, function (processing) {
                if (element.hasClass('processing') && ! processing) {
                    return element.removeClass('processing');
                }

                if (! element.hasClass('processing') && processing) {
                    return element.addClass('processing');
                }
            });
        };

        return {
            restrict: 'A',
            require: '^form',
            compile: function(element, attrs) {
                if (! element.hasClass('form-group') && ! element.hasClass('input-group') && ! element.hasClass('checkbox')) {
                    throw "input-group element does not have the 'input-group' class";
                }

                return linkFn;
            }
        };
    }
])

.directive('ngInitial', [
    '$parse',
    function($parse) {
        return {
            restrict: "A",
            compile: function($element, $attrs) {
                var initialValue = $attrs.value || $element.val(),
                    type = $attrs.type;

                // Why use standards when you can change the default value
                // to a boolean? Thanks Angular!
                if (type === 'checkbox') {
                    initialValue = $element[0].defaultChecked;
                }

                return {
                    pre: function($scope, $element, $attrs) {
                        $parse($attrs.ngModel).assign($scope, initialValue);
                    }
                }
            }
        }
    }
])

.directive('listItem', function () {
    return {
        restrict: "A",
        link: function(scope, element, attrs, controllers) {
            var inputElement = element[0].querySelector('input'),
                inputNgElement = angular.element(inputElement);

            element.addClass('list-item');

            element.bind('mouseenter', function () {
                if (typeof inputNgElement.attr('disabled') == 'undefined') {
                    element.addClass('hover');
                }
            });

            element.bind('mouseleave', function () {
                element.removeClass('hover');
            });

            scope.$watch(inputNgElement.attr('ng-model'), function () {
                if (inputNgElement.is(':checked')) {
                    element.addClass('checked');
                } else {
                    element.removeClass('checked');
                }
            });

            scope.$watch(inputNgElement.attr('ng-disabled'), function (newVal) {
                if (typeof inputNgElement.attr('disabled') != 'undefined') {
                    element.addClass('disabled');
                } else {
                    element.removeClass('disabled');
                }
            });
        }
    }
})

.directive('rowLink', function ($timeout) {
    return {
        restrict: "A",
        link: function(scope, element, attrs, controllers) {
            var inputElement = element[0].querySelector('input'),
                inputNgElement = angular.element(inputElement);

            element.bind('mouseenter mouseleave', function () {
                if (typeof inputNgElement.attr('disabled') == 'undefined') {
                    element.toggleClass('hover');
                }
            });

            element.bind('click', function (event) {
                if (angular.element(event.target).closest('.controls').length !== 0) {
                    return;
                }

                inputElement.click();
            });
        }
    }
})

.directive('size', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            multiplier: '=',
            divider: '=',
            value: '=',
            unit: '=',
        },
        template : '<span class="size">{{ formatted }} <span class="data-type">{{ sizeUnit }}</span></span>',
        link: function (scope, element, attrs) {
            var formatDiskSpace = function(bytes) {
                var magnitude = Math.floor( Math.log(bytes) / Math.log(1024) );
                var round = function(num, decimals) { return Math.round(num*Math.pow(10, decimals))/Math.pow(10, decimals); };
                var unit = ['bytes', 'KB', 'MB', 'GB', 'TB'];
                magnitude = (magnitude <= (unit.length -1)) ? magnitude : unit.length -1;
                return {
                    amount: bytes/Math.pow(1024, magnitude),
                    unit: unit[magnitude]
                };
            };
            var updateLabel = function () {
                var formatted = 0,
                    value = scope.value,
                    divider = scope.divider,
                    multiplier = scope.multiplier,
                    unit = (attrs.unit) ? attrs.unit : 'GB';
                if (typeof divider != 'undefined') {
                    formatted = (value / divider);
                } else {
                    formatted = (value * multiplier);
                }
                if (attrs.divider === 'relative') {
                    var data = formatDiskSpace(value);
                    formatted = data.amount;
                    unit = data.unit;
                }

                scope.formatted = formatted.toFixed(1);
                scope.sizeUnit = unit;
            }

            scope.$watch('value', function () {
                updateLabel();
            }, true);

            updateLabel();
        }
    };
})

.directive('price', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            value: '@',
            divider: '=',
            multiplier: '=',
            period: '=',
        },
        template : '<span>&euro; {{ formatted[0] }} <span class="data-type">{{ formatted[1] }}</span><span class="small text-muted" ng-if="unit">{{ unit }}</span></span>',
        link: function (scope, element, attrs, controller) {
            var divider = attrs.divider,
                multiplier = attrs.multiplier,
                period = attrs.period;

            scope.formatted = ['0', '00'];
            scope.unit = typeof period != 'undefined'
                ? ' / '+period
                : '';

            var updatePrice = function (price, divider, multiplier, period) {
                if (typeof divider != 'undefined') {
                    scope.formatted = (price / divider).toFixed(2).split('.');
                } else {
                    scope.formatted = (price * multiplier).toFixed(2).split('.');
                }
            };

            attrs.$observe('value', function (value) {
                updatePrice(value, divider, multiplier, period);
            });

            updatePrice(attrs.value, divider, multiplier, period);
        }
    };
});
