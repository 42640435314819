'use strict';

angular.module('forms.filters', ['ngSanitize'])

.filter('trusted', ['$sanitize', function($sanitize) {
    return function (val) {
        let $element = angular.element('<span></span>');

        $element.html($sanitize(val));

        return $element.html();
    };
}]);
